import React from 'react';
import "./Footer.css"

const Footer = () => {
  return (
    <div id="footer">
      Footer
    </div>
  )
}

export default Footer
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login form span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .login form button {
    margin: 10px;
    padding: 5px;
    border: none;
    border-radius: 3px;
    width: 170px;
    cursor: pointer;
    background-image: linear-gradient(
      102.4deg,
      rgba(253, 189, 85, 1) 7.8%,
      rgba(249, 131, 255, 1) 100.3%
    );
    color: #fff;
  }

#errorMessage {
  color: red;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf;;;;KAIC;IACD,WAAW;EACb;;AAEF;EACE,UAAU;EACV,aAAa;AACf","sourcesContent":[".login {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 100vh;\r\n  }\r\n  \r\n  .login form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n    max-width: 400px;\r\n    padding: 20px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .login form span {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 10px;\r\n  }\r\n  \r\n  .login form button {\r\n    margin: 10px;\r\n    padding: 5px;\r\n    border: none;\r\n    border-radius: 3px;\r\n    width: 170px;\r\n    cursor: pointer;\r\n    background-image: linear-gradient(\r\n      102.4deg,\r\n      rgba(253, 189, 85, 1) 7.8%,\r\n      rgba(249, 131, 255, 1) 100.3%\r\n    );\r\n    color: #fff;\r\n  }\r\n\r\n#errorMessage {\r\n  color: red;\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#userInfoDiv {
    min-width: 100vw;
}
#userInfo .Prop {
    padding-left: 5px;
    padding-right: 10px;
    text-align: right;
}

#userInfo .Val {
    border: solid 1px;
    border-color: grey;
    border-radius: 5px;
    width: 20vw;
}

.modalOpts {
    border: none;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 5px;
    padding: 5px;
    float: right;
}
  
.modalOpts:hover {
    cursor: pointer;
}

.posAction {
    background-color: green;
} 

.negAction {
    background-color: red;
}

#errorMessage {
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/routes/Account.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd","sourcesContent":["#userInfoDiv {\r\n    min-width: 100vw;\r\n}\r\n#userInfo .Prop {\r\n    padding-left: 5px;\r\n    padding-right: 10px;\r\n    text-align: right;\r\n}\r\n\r\n#userInfo .Val {\r\n    border: solid 1px;\r\n    border-color: grey;\r\n    border-radius: 5px;\r\n    width: 20vw;\r\n}\r\n\r\n.modalOpts {\r\n    border: none;\r\n    text-align: center;\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    margin: 4px 2px;\r\n    border-radius: 5px;\r\n    padding: 5px;\r\n    float: right;\r\n}\r\n  \r\n.modalOpts:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n.posAction {\r\n    background-color: green;\r\n} \r\n\r\n.negAction {\r\n    background-color: red;\r\n}\r\n\r\n#errorMessage {\r\n    color: red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

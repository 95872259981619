// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anchorButton {
    border: none;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 2px 0px 0px 1px;
    border-radius: 5px;
    padding: 2px;
    display: inline-block;
    color: white;
    background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/routes/Administration.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".anchorButton {\n    border: none;\n    text-align: center;\n    text-decoration: none;\n    font-size: 16px;\n    margin: 2px 0px 0px 1px;\n    border-radius: 5px;\n    padding: 2px;\n    display: inline-block;\n    color: white;\n    background-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react'
import axios from 'axios'
import "./RolesTable.css"
import { VscEdit } from "react-icons/vsc";

const RolesTable = () => {
  const [roles, setRoles] = useState([])
  
  const getData = async() => {
    const res = await axios.get('/api/role', {headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token")
          ? sessionStorage.getItem("token")
          : null
      }`
    }})
    setRoles(res.data)
  }
  
  useEffect(() => {
    getData()
  }, [])
  
  return (
    <div>
      <table id="rolesTable">
        <tbody>
          <tr>
            <th id="selectors"></th>
            <th>Role Name</th>
          </tr>
          {roles.map(r => <tr key={r._id}><td><VscEdit alt="Edit course properties"/></td><td>{r.name}</td></tr>)}
        </tbody>
      </table>
    </div>
  )
}

export default RolesTable;
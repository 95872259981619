// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#logoutInfo {
    /* set logo to 20% screen width and centre */
    width: 10%; 
    margin: auto;

    /* set logo and text to be in the middle vertically */
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
}

#logoutInfo h4 {
    font-size: large;
}

@media (prefers-reduced-motion: no-preference) {
  #logoutLogo {
    animation: App-logo-spin infinite 3s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/Logout.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,UAAU;IACV,YAAY;;IAEZ,qDAAqD;IACrD,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;;IAEhC,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;EACE;IACE,2CAA2C;EAC7C;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["#logoutInfo {\r\n    /* set logo to 20% screen width and centre */\r\n    width: 10%; \r\n    margin: auto;\r\n\r\n    /* set logo and text to be in the middle vertically */\r\n    margin: 0;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n\r\n    text-align: center;\r\n}\r\n\r\n#logoutInfo h4 {\r\n    font-size: large;\r\n}\r\n\r\n@media (prefers-reduced-motion: no-preference) {\r\n  #logoutLogo {\r\n    animation: App-logo-spin infinite 3s linear;\r\n  }\r\n}\r\n\r\n@keyframes App-logo-spin {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from 'react'
import axios from 'axios'
import "./ClassTable.css"
import { VscEdit } from "react-icons/vsc";

const ClassesTable = () => {
  const [classes, setClasses] = useState([])
  
  const getData = async() => {
    const res = await axios.get('/api/class', {headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token")
          ? sessionStorage.getItem("token")
          : null
      }`
    }})
    setClasses(res.data)
  }
  
  useEffect(() => {
    getData()
  }, [])
  
  return (
    <div>
      <table id="classTable">
        <tbody>
          <tr>
            <th id="selectors"></th>
            <th>className</th>
          </tr>
          {classes.map(c => <tr key={c._id}><td><VscEdit alt="Edit class properties"/></td><td>{c.className}</td></tr>)}
        </tbody>
      </table>
    </div>
  )
}

export default ClassesTable;
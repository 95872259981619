import React, { useState, useEffect } from 'react';
import Hamburger from 'hamburger-react'
import "./Navigation.css"
import logo from "../images/TrackMeLogoOnly.png"
import { VscAccount, VscSettingsGear } from "react-icons/vsc";

const testIsAdmin = () => {
  if(sessionStorage.roles) {
    return sessionStorage.roles.includes("ADMIN");
  }
}

const isAdmin = testIsAdmin();

const administration = () => {
  window.location.href="/admin";
}

const logout = () => {
  window.location.href="/logout";
}

const accPreferences = () => {
  window.location.href="/account/preferences";
}

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  return (
    <div>
    {!matches && (
      <nav className={isOpen ? 'isOpen' : ''}>
        <Hamburger toggled={isOpen} toggle={setIsOpen} />

        {isOpen  && (
          <>
            <a id="home" href="/"><img src={logo} alt="Logo" /></a>
            <a href="/about">About</a>
            <a href="/contact">Contact</a>
            <button id="logout" onClick={logout}>Log Out</button>
            <button id="account" onClick={accPreferences}><VscAccount alt="Account preferences"/></button>
            {isAdmin && (
              <button id="administration" onClick={administration}><VscSettingsGear alt="Account preferences"/></button>
            )}
          </>
        )}
      </nav>
    )}
    {matches && (
      <nav className={isOpen ? 'isOpen' : ''}>
        <a id="home"  href="/"><img src={logo} alt="Logo" /></a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
        <button id="logout" onClick={logout}>Log Out</button>
        <button id="account" onClick={accPreferences}><VscAccount alt="Account preferences"/></button>
        {isAdmin && (
          <button id="administration" onClick={administration}><VscSettingsGear alt="Account preferences"/></button>
        )}
      </nav>
    )}
  </div>
)
}

export default NavigationBar
import React from 'react';
import logo from "../images/logout.svg"
import "./Logout.css"

const signout = async () => {
    const response = await fetch(
        `/api/auth/signout`,
        {
          method: "POST",
          headers: {
            "Authorization": sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

    await response.json();
    
    setTimeout(() => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("roles");
        window.location.href="/";
    },3000)
}

const Logout = () => {    
    signout()
    return (
        <div id="logoutInfo">
            <img id="logoutLogo" src={logo} alt="loading circle"></img>
            <h4>You are being signed out</h4>
        </div>
    )
}

export default Logout;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    vertical-align: top;
}

input, select {
    width: 100%;
    max-width: 100%;
    padding: 0%;
    margin: 0%;
}`, "",{"version":3,"sources":["webpack://./src/routes/NewUser.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,WAAW;IACX,UAAU;AACd","sourcesContent":["label {\r\n    vertical-align: top;\r\n}\r\n\r\ninput, select {\r\n    width: 100%;\r\n    max-width: 100%;\r\n    padding: 0%;\r\n    margin: 0%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

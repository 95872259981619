import React, {useEffect, useState} from 'react'
import axios from 'axios'
import "./UsersTable.css"
import { VscEdit, VscKey } from "react-icons/vsc";
import { TiUserDeleteOutline } from "react-icons/ti";
import Modal from 'react-modal';

let userIdToChange = "";
let usernameToChange = "";

const getRoleNames = (roles) => {
  let roleStr = ""
  roles.forEach(element => {
    roleStr += element.name + ", "
  });
  roleStr = roleStr.substring(0, roleStr.length - 2)
  return roleStr
}

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const UsersTable = () => {
  const [users, setUsers] = useState([])
  
  const getData = async() => {
    const res = await axios.get('/api/users/allUsers', {headers: {
      Authorization: `Bearer ${
        sessionStorage.getItem("token")
          ? sessionStorage.getItem("token")
          : null
      }`
    }})
    setUsers(res.data)
  }
  
  useEffect(() => {
    getData()
  }, [])

  const [editUserIsOpen, openEditUser] = React.useState(false);
  const [changePasswordIsOpen, openChangePassword] = React.useState(false);

  function openModal() {
    openEditUser(true);
  }

  function afterOpenModal() {
  }

  function openPassModal () {
    openChangePassword(true);
  }

  function closePassModal () {
    openChangePassword(false);
  }

  function openChangePasswordDialog(e, uid, username) {
    userIdToChange = uid;
    usernameToChange = username;
    openPassModal();
  }

  const changePassword = async (event) => {
    event.preventDefault();
    const { new_password, confirm_new_password } = event.target;

    if(new_password.value === confirm_new_password.value){
      const response = await fetch(
        `/api/auth/changePassword`,
        {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${
              sessionStorage.getItem("token")
                ? sessionStorage.getItem("token")
                : null
            }`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
              uid: userIdToChange,
              new: new_password.value,
          }),
        }
      )
  
      const data = await response.json();
      if(data.message === "Success") {
        closePassModal();
      }
    } else {
      document.getElementById("errorMessage").style="display: block;";
    }
  }

  function closeModal(e, save) {
    if(save) {
      openEditUser(false);
    } else {
      openEditUser(false);
    }
  }
  
  const EditUsers = (e, uid) => {
    openModal();
  }

  const deleteUser = async (e, uid) => {
    const response = await fetch(
      `/api/users/deleteUser`,
      {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : null
          }`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            uid: uid,
        }),
      }
    )

    await response.json();

    window.location.reload();
  }
  
  return (
    <div>
      <table id="usersTable">
        <tbody>
          <tr>
            <th id="selectors"></th>
            <th>Username</th>
            <th>Roles</th>
          </tr>
          {users.map(u => <tr key={u._id}><td><VscEdit onClick={(e) => EditUsers(e, u._id)} alt="Edit user properties"/><VscKey onClick={(e) => openChangePasswordDialog(e, u._id, u.username)} alt="Change password"/><TiUserDeleteOutline onClick={(e) => deleteUser(e, u._id)} alt="Delete user"/></td><td>{u.username}</td><td>{getRoleNames(u.roles)}</td></tr>)}
        </tbody>
      </table>
      
      <Modal
        isOpen={editUserIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={openModal}
        style={customStyles}
        contentLabel="Edit user"
      >
        <h2>Editing...</h2>
        <form>
          <table>
            <tbody>
              <tr><td><label>Username: </label></td><td><input type="text" name="username"></input></td></tr>
              <tr><td><label>Email: </label></td><td><input type="text" name="email"></input></td></tr>
              <tr><td><label>Roles: </label></td><td><input type="text" name="roles"></input></td></tr>
              <tr><td><label>Password: </label></td><td><input type="password" defaultValue="****************" name="password"></input></td></tr>
              <tr><td><label>Confirm Password: </label></td><td><input type="password" defaultValue="****************" name="confirm_password"></input></td></tr>
            </tbody>
          </table>
        </form>
        <button className="modalOpts negAction" onClick={(e) => closeModal(e, false)}>Cancel</button>
        <button className="modalOpts posAction" onClick={(e) => closeModal(e, true)}>Save & Close</button>
        
      </Modal>

      <Modal
        isOpen={changePasswordIsOpen}
        onRequestClose={closePassModal}
        style={customStyles}
        contentLabel="Change password"
      >
        <h2>Change a password</h2>
        <p>You are editing the password for {usernameToChange}</p>
        <form onSubmit={changePassword}>
          <table>
            <tbody>
              <tr><td><label>New Password: </label></td><td><input type="password" name="new_password"></input></td></tr>
              <tr><td><label>Confirm Password: </label></td><td><input type="password" name="confirm_new_password"></input></td></tr>
            </tbody>
          </table>
        <p id="errorMessage">Passwords do not match</p>
        <input type="submit" className="modalOpts posAction" value="Save & Close"></input>
        </form>
        <button className="modalOpts negAction" onClick={closePassModal}>Cancel</button>
        
        
      </Modal>
    </div>
  )
}

export default UsersTable;
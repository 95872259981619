import React from 'react';
import "./Account.css";
import Modal from 'react-modal';

const Account = () => {
    const resetPassword = async (event) => {
        event.preventDefault();
        const { old_password, new_password, confirm_password } = event.target;
        
        if(!(new_password.value === confirm_password.value)) {
            document.getElementById("errorMessage").innerHTML = "New password and confirmation do not match";
            document.getElementById("new_password").value = ""
            document.getElementById("confirm_password").value = ""
            return
        }

        const response = await fetch(
            `/api/auth/resetPassword`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  username: sessionStorage.username,
                  old: old_password.value,
                  new: new_password.value
              }),
            }
        )
  
        await response.json();
        
        window.location.reload();
    }

    Modal.setAppElement('#root');
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal(e) {
        setIsOpen(false);
    }
    return (
        <div id="userInfoDiv">
            <h1>Account Settings</h1>
            <table id="userInfo">
                <tbody>
                    <tr><td className='Prop'>Username</td><td className='Val'>{sessionStorage.getItem("username")}</td></tr>
                    <tr><td className='Prop'>Email</td><td className='Val'>{sessionStorage.getItem("email")}</td></tr>
                    <tr><td className='Prop'>Roles</td><td className='Val'>{sessionStorage.getItem("roles").replaceAll("ROLE_","").replaceAll(",",", ")}</td></tr>
                </tbody>
            </table>
            <button onClick={openModal}>Change Password</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

            <h2>Change Password</h2>
            <form onSubmit={resetPassword}>
            <table>
                <tbody>
                    <tr><td><label>Old Password: </label></td><td><input type="password" name="old_password" id="old_password"></input></td></tr>
                    <tr><td><label>New Password: </label></td><td><input type="password" name="new_password" id="new_password"></input></td></tr>
                    <tr><td><label>Confirm Password: </label></td><td><input type="password"  name="confirm_password" id="confirm_password"></input></td></tr>
                </tbody>
            </table>
            <p id="errorMessage"></p>
            <button className="modalOpts negAction" onClick={closeModal}>Cancel</button>
            <input type="submit" className="modalOpts posAction" value="Change"></input>
            </form>
            

            </Modal>
        </div>
    )
}

export default Account;
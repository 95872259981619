import React from 'react';
import "./Administration.css";
import UsersTable from "../components/UsersTable"
import RolesTable from "../components/RolesTable"
import CoursesTable from "../components/CoursesTable"
import ClassesTable from "../components/ClassTable"

const Administration = () => {
    return (
        <>
            <h1>Administration</h1>
            <h2>Users</h2>
            <UsersTable />
            <a className="anchorButton" href="/admin/newUser">New User</a>
            <h2>Roles</h2>
            <RolesTable />
            {<a className="anchorButton" href="/admin/newRole">New Role</a>}
            <h2>Courses</h2>
            <CoursesTable />
            <a className="anchorButton" href="/admin/newCourse">New Course</a>
            <h2>Classes</h2>
            <ClassesTable />
            <a className="anchorButton" href="/admin/newClass">New Class</a>
        </>
    )
}

export default Administration;
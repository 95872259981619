import React from 'react'
import "./App.css"
import NavigationBar from "./components/Navigation"
import Footer from "./components/Footer"
import Home from "./routes/Home"
import Logout from "./routes/Logout"
import Account from "./routes/Account"
import NewUser from "./routes/NewUser"
import Administration from "./routes/Administration"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <div id="content">
      <main>
        <NavigationBar />
        <div id="maincontent">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<h4>About</h4>} />
            <Route path="/contact" element={<h4>Contact</h4>} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/account/preferences" element={<Account />} />
            <Route path="/admin/newUser" element={<NewUser />} />
            <Route path="/admin" element={<Administration />} />
          </Routes>
        </Router>
        </div>
        <Footer />
      </main>
      
    </div>
  )
}

export default App
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const token = sessionStorage?.getItem("token");

const validateToken = async (token) => {
    if(token) {
        const response = await fetch(
            `/api/auth/validateToken`,
            {
              method: "POST",
              headers: {
                "Authorization": token,
                "Content-Type": "application/json",
              }
            }
          );
      
          const data = await response.json();
          if(data.message === "Token invalid" || data.message === "Unauthorized!") {
            window.location.href="/logout";
          }
    }
}

if(!(window.location.href.endsWith("/logout"))) {
    validateToken(token);
}

root.render(
    <React.StrictMode>
    {token ? <App /> : <Login />}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import "./NewUser.css";

function generatePass(chars) {
    let pass = "";
    let str =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
        "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= chars; i++) {
        let char = Math.floor(Math.random() * str.length + 1);

        pass += str.charAt(char);
    }

    return pass;
}

const NewUser = () => {
    const submitNewUser = async (event) => {
        event.preventDefault();
        const { emailAddr, roles } = event.target;
        const password = generatePass(12);
    
        let rolesArr = Array.from(roles.selectedOptions, option => option.value);
    
        const response = await fetch(
          `/api/auth/signup`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                authorization: sessionStorage.token,
                username: emailAddr.value,
                email: emailAddr.value,
                roles: rolesArr,
                password: password,
            }),
          }
        )

        const data = await response.json();
        if(data.message === "User was registered successfully!"){ //TODO: need to change this to present error on screen
            console.log("success");
        } else {
            console.log("failure", data);
        }
        window.location.reload();
    }

    return (
        <div>
            <form id="newUser" onSubmit={submitNewUser}>
                <table>
                    <tbody>
                    <tr><td>
                    <label>Email: </label> <input type="email" name="emailAddr"></input>
                    </td></tr><tr><td>
                    <label>Roles: </label> <select name="roles" multiple form="newUser" size="10">
                        <option>parent</option>
                        <option>student</option>
                        <option>teacher</option>
                        <option>manager</option>
                        <option>hr</option>
                        <option>admin</option>
                    </select>
                    </td></tr><tr><td>
                        <input type="submit"></input>
                        </td></tr>
                    </tbody>
                </table>
                
            </form>
        </div>
    )
};

export default NewUser;
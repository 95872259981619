// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#courseTable {
    display: grid;
    border-collapse: collapse;
  
    th, td {
      border: 1px solid black;
      gap: 0px;
    }

    input {
      width: 100%;
    }

    svg:hover {
      cursor: pointer;
    }
}

.modalOpts {
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px;
  border-radius: 5px;
  padding: 5px;
  float: right;
}

.modalOpts:hover {
  cursor: pointer;
}

.posAction {
  background-color: green;
} 

.negAction {
  background-color: red;
}

#errorMessage {
  color: red;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CoursesTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;;IAEzB;MACE,uBAAuB;MACvB,QAAQ;IACV;;IAEA;MACE,WAAW;IACb;;IAEA;MACE,eAAe;IACjB;AACJ;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,aAAa;AACf","sourcesContent":["#courseTable {\r\n    display: grid;\r\n    border-collapse: collapse;\r\n  \r\n    th, td {\r\n      border: 1px solid black;\r\n      gap: 0px;\r\n    }\r\n\r\n    input {\r\n      width: 100%;\r\n    }\r\n\r\n    svg:hover {\r\n      cursor: pointer;\r\n    }\r\n}\r\n\r\n.modalOpts {\r\n  border: none;\r\n  text-align: center;\r\n  text-decoration: none;\r\n  font-size: 16px;\r\n  margin: 4px;\r\n  border-radius: 5px;\r\n  padding: 5px;\r\n  float: right;\r\n}\r\n\r\n.modalOpts:hover {\r\n  cursor: pointer;\r\n}\r\n\r\n.posAction {\r\n  background-color: green;\r\n} \r\n\r\n.negAction {\r\n  background-color: red;\r\n}\r\n\r\n#errorMessage {\r\n  color: red;\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
